<template>
  <div>
    <div class="page-wrapper down">
      <Page
        show-total
        :total="tableParams.totalItems"
        :current="tableParams.Tpage.currentPage"
        @on-change="handleCurrentChange"
        :page-size="tableParams.Tpage.currentSize"
      ></Page>
    </div>
    <Table
      :width="tableParams.tableWidth"
      :loading="tableParams.loading"
      size="small"
      border
      :columns="tableParams.columnsFormat"
      :data="tableParams.contentData"
      :no-data-text="tableParams.noDataText"
      :row-class-name="rowClassName"
      style="margin:0 auto;"
    ></Table>
    <div class="page-wrapper down">
      <Page
        background
        @on-page-size-change="handleSizeChange"
        @on-change="handleCurrentChange"
        :current-page="tableParams.Tpage.currentPage"
        :page-size-opts="tableParams.pageSizeOptions"
        :page-size="tableParams.Tpage.currentSize"
        show-total
        show-elevator
        show-sizer
        :total="tableParams.totalItems"
      ></Page>
    </div>
  </div>
</template>
<script>
export default {
  name: "listWithPage",
  props: {
    tableParams: {
      type: Object,
      required: true,
      default: function() {
        return {
          Tpage: {
            currentPage: 1,
            currentSize: 20
          },
          loading: true,
          columnsFormat: [],
          contentData: [],
          totalItems: 0,
          noDataText: "无数据",
          tableWidth: 1230,
          pageSizeOptions: [20, 50, 100, 200, 400]
        };
      }
    }
  },
  computed: {
  },
  methods: {
    handleCurrentChange(value) {
      this.tableParams.Tpage.currentPage = value;
      this.$emit("handlePage", {
        currentPage: value,
        currentSize: this.tableParams.Tpage.currentSize
      });
    },
    handleSizeChange(value) {
      this.tableParams.Tpage.currentSize = value;
      this.$emit("handlePage", { currentPage: 1, currentSize: value });
    },
    rowClassName() {
      return "";
    },
    // rowClassName(row) {
    //   let nday = new Date();
    //   let limitday = null;
    //   if(row.limit_date){
    //     limitday = new Date(...row.limit_date.split('-'));
    //   }
    //   if (
    //     (row.risk_correct_stat === "已完成"|| row.correct_stat === "已完成") &&
    //     row.confirm_stat === "未确认"
    //   ) {
    //     return "demo-table-confirm-row";
    //   }else if (row.risk_correct_stat === "整改中" || row.correct_stat === "整改中") {
    //     if (
    //     limitday&&limitday>nday
    //       ) {
    //         return "demo-table-limit-row";
    //       }else if(limitday&&limitday<=nday){
    //         return "demo-table-error-row";
    //       }else{
    //         return "demo-table-wait-row";
    //       }
    //   } else if (
    //     row.risk_correct_stat === "常态化管理" ||
    //     row.correct_stat === "常态化管理"
    //   ) {
    //     return "demo-table-info-row";
    //   }else if (
    //     row.if_danger === "是"
    //   ) {
    //     return "demo-table-danger-row";
    //   }else{
    //     return "";
    //   }
      
    // },
  },
  data() {
    return {};
  }
};
</script>
<style scoped>
.page-wrapper {
  text-align: right;
  padding: 10px 0;
  margin-left:-50px;
}
.down {
  padding-right: 10px;
}
</style>