<template>
  <div>
    <div class="outer-wrapper" :style="{width:main_width+'px'}">
      <div class="m-option-wrapper">
        <div class="button-wrapper">
          <div class="keyword-input-wrapper">
            <label class="keyword-label">
              关键字：
              <Input v-model="keyword" placeholder="请输入..." clearable class="keyword-input" />
            </label>
          </div>
          <Button class="main-button" type="primary" icon="ios-search" @click="goSearch">查询</Button>
        </div>
      </div>
      <div class="table-wrapper" id="data-list">
            <list-with-page :tableParams="tableParams" @handlePage="handlePage"></list-with-page>
      </div>
    </div>
  </div>
</template>
<script>
import listWithPage from "./ListWithPage.vue";
let userToken = localStorage.getItem("current_user_token");

export default {
  name: "queryList",
  components: {
    listWithPage,
  },
  props: {
    "columnsFormatData":{
        type:Array,
        required:true,
    },
    "uri_name":{
        type:String,
        default:'/list'
    },
    "main_width":{
        type:String,
        default:'1200'
    },
    'askData':{
        type:String,
        required:true
    },
    'refreshData':{
        type:Number,
        default:1
    },
    'selectedRow':{
      type:Array,
      default:function(){
        return [];
      }
    }
    },
  model:{
    prop:'selectedRow',
    event:'on-selection-change'
  },
  data() {
    return {
        tableParams: {
          Tpage: {
            currentPage: 1,
            currentSize: 20
          },
          totalItems: 0,
          noDataText: "无数据",
          loading: true,
          columnsFormat: this.columnsFormatData,
          contentData: [],
          tableWidth: parseInt(this.main_width)-30,
          pageSizeOptions: [20, 50, 100, 200, 400]
        }, 
        keyword: "",
        selected:this.selectedRow,
    };
  },
  computed: {
  },
  created() {
    this.getData();
  },
  watch:{
      refreshData:{
        immediate: true,
        handler(){
          this.keyword = "";
          this.getData();
          this.selected = [];
        }
      },
      selected:function(){
        this.$emit('on-selection-change',this.selected);
      }
  },
  methods: {
    handlePage(value) {
      this.Tpage = value;
      this.getData();
    },
    getData() {
      this.tableParams.loading = true;
      this.tableParams.noDataText = "数据加载中...";
      this.$axios
        .post(this.$url + this.uri_name, {
          page: this.tableParams.Tpage,
          askData:this.askData,
          keyword:this.keyword,
          userToken: userToken
        })
        .then(
          response => {
            this.tableParams.contentData = response.data.res_record;
            this.tableParams.totalItems = response.data.total_n;
            this.tableParams.loading = false;
            this.tableParams.noDataText = "暂无数据";
          }
        );
    },
    // handleCurrentChange(value) {
    //   this.tableParams.Tpage.currentPage = value;
    //   this.getData();
    // },
    // handleSizeChange(value) {
    //   this.tableParams.Tpage.currentSize = value;
    //   this.tableParams.Tpage.currentPage = 1;
    //   this.getData();
    // },
    goSearch() {
      this.tableParams.Tpage.currentPage = 1;
      this.getData();
      this.goAnchor("#data-list");
      this.selected = [];
    },
    goAnchor(selector) {
      this.$el.querySelector(selector).scrollIntoView();
    }
  },  
};
</script>
<style scoped>
.outer-wrapper {
  width: 100%;
  margin: 0 auto;
}

.m-option-wrapper {
  box-shadow: 2px 2px 1px #aaaaaa;
  border: 1px solid #e7e7e7;
  padding: 2px;
  overflow: hidden;
}

.button-wrapper {
  padding-top: 10px;
  text-align: right;
  height: 50px;
}

.main-button {
  margin-right: 30px;
}

.main-button {
  background-color: #993333;
  color: #ddd;
  border: none;
}

.main-button:hover {
  background-color: #993333;
  border: none;
  color: #fff;
}

.re-button {
  margin-right: 6%;
}

.table-wrapper {
  margin-top: 10px;
  box-shadow: 2px 2px 1px #aaaaaa;
  border: 1px solid #e7e7e7;
  overflow: hidden;
}

.echarts-wrapper {
  margin: 0 auto;
}

.keyword-input-wrapper {
  float: left;
  margin-left: 20px;
}

.keyword-label {
  color: #999;
  font-size: 14px;
}

.keyword-input {
  width: 350px;
  margin-left: 20px;
}

.ins-wrapper {
  font-size: 16px;
  padding: 20px 50px;
  text-align: center;
}

.navImg {
  margin: 0 auto;
  width: 800px;
}

.pane_label {
  font-size: 15px;
  font-weight: 800;
}

.catagory-option-wrapper {
  border: 2px dashed #b4a078;
  margin: 10px 20px;
  padding: 10px 0;
  overflow: hidden;
}

.option-title-wrapper {
  color: #006699;
  font-size: 14px;
  font-family: SimHei;
  text-align: center;
  padding-top: 10px;
  font-weight: 700;
}

.title-box {
  border-left: 1px solid #006699;
  border-right: 1px solid #006699;
  padding: 0 15px;
}

.single-chart-wrapper {
  margin-bottom: 30px;
}
</style>